import * as actions from './organizationJobRole.actions';
import { EntityState, createEntityAdapter, Update } from '@ngrx/entity';
import { createFeatureSelector, createSelector, Store } from '@ngrx/store';
import { OrganizationJobRoleModel } from '@smarttask-common/src/lib/models/OrganizationUser/OrganizationJobRoleModel';

export const organizationJobRoleAdapter = createEntityAdapter<OrganizationJobRoleModel>({
    selectId: (x) => x.job_role,
});
export interface OrganizationJobRoleState extends EntityState<OrganizationJobRoleModel> {
    selected_job_role: string;
}

export const initialState: OrganizationJobRoleState = organizationJobRoleAdapter.getInitialState({
    selected_job_role: undefined,
});

export function OrganizationJobRoleReducers(state: OrganizationJobRoleState = initialState, action: actions.OrganizationJobRoleActions) {
    switch (action.type) {
        case actions.OrganizationJobRoleActionTypes.UPDATE_SELECTED_ROLE:
            state = { ...state, selected_job_role: action.selected_job_role };
            //console.log(state);
            return state;

        case actions.OrganizationJobRoleActionTypes.ADD_JOB_ROLE:
            return organizationJobRoleAdapter.addOne(action.job_role, state);

        case actions.OrganizationJobRoleActionTypes.ADD_JOB_ROLES:
            return organizationJobRoleAdapter.addMany(action.job_roles, state);

        case actions.OrganizationJobRoleActionTypes.UPSERT_JOB_ROLE:
            return organizationJobRoleAdapter.upsertOne(action.job_role, state);

        case actions.OrganizationJobRoleActionTypes.UPSERT_JOB_ROLES:
            return organizationJobRoleAdapter.upsertMany(action.job_roles, state);

        case actions.OrganizationJobRoleActionTypes.UPDATE_JOB_ROLE:
            return organizationJobRoleAdapter.updateOne(action.job_role, state);

        case actions.OrganizationJobRoleActionTypes.UPDATE_JOB_ROLES:
            return organizationJobRoleAdapter.updateMany(action.job_roles, state);

        case actions.OrganizationJobRoleActionTypes.DELETE_JOB_ROLE:
            return organizationJobRoleAdapter.removeOne(action.id, state);

        case actions.OrganizationJobRoleActionTypes.DELETE_JOB_ROLES:
            return organizationJobRoleAdapter.removeMany(action.ids, state);

        case actions.OrganizationJobRoleActionTypes.DELETE_ALL_JOB_ROLES:
            return organizationJobRoleAdapter.removeMany(action.ids, state);

        case actions.OrganizationJobRoleActionTypes.DELETE_ALL:
            return organizationJobRoleAdapter.removeAll(state);

        default:
            return state;
    }
}

export const getOrganizationJobRoleState = createFeatureSelector<OrganizationJobRoleState>('organizationJobRole');

export const { selectIds, selectEntities, selectAll, selectTotal } = organizationJobRoleAdapter.getSelectors(getOrganizationJobRoleState);

const getSelectedJobRoleValueState = (state: OrganizationJobRoleState) => {
    return state.selected_job_role;
};
export const getSelectedJobRoleValue = createSelector(getOrganizationJobRoleState, getSelectedJobRoleValueState);

export const getSelectedJobRole = createSelector(getOrganizationJobRoleState, (state: OrganizationJobRoleState) => {
    if (state.selected_job_role == undefined) {
        return undefined;
    }
    return state.entities[state.selected_job_role];
});
