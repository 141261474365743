import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { OrganizationJobRoleModel } from '@smarttask-common/src/lib/models/OrganizationUser/OrganizationJobRoleModel';

export enum OrganizationJobRoleActionTypes {
    ADD_JOB_ROLE = '[OrganizationJobRole] Create JobRole',
    ADD_JOB_ROLES = '[OrganizationJobRole] Add JobRoles',
    UPSERT_JOB_ROLE = '[OrganizationJobRole] Upsert JobRole',
    UPSERT_JOB_ROLES = '[OrganizationJobRole] Upsert JobRoles',
    UPDATE_JOB_ROLE = '[OrganizationJobRole] Update JobRole',
    UPDATE_JOB_ROLES = '[OrganizationJobRole] Update JobRoles',
    DELETE_JOB_ROLE = '[OrganizationJobRole] Delete JobRole',
    DELETE_JOB_ROLES = '[OrganizationJobRole] Delete JobRoles',
    DELETE_ALL_JOB_ROLES = '[OrganizationJobRole] Delete All JobRoles',
    DELETE_ALL = '[OrganizationJobRole] Delete All',

    UPDATE_SELECTED_ROLE = '[OrganizationJobRole] Update SelectedRole',
}

export class UpdateSelectedJobRole implements Action {
    readonly type = OrganizationJobRoleActionTypes.UPDATE_SELECTED_ROLE;
    constructor(public selected_job_role: string) {}
}

export class AddOne implements Action {
    readonly type = OrganizationJobRoleActionTypes.ADD_JOB_ROLE;
    constructor(public job_role: OrganizationJobRoleModel) {}
}

export class AddMany implements Action {
    readonly type = OrganizationJobRoleActionTypes.ADD_JOB_ROLES;
    constructor(public job_roles: Array<OrganizationJobRoleModel>) {}
}

export class UpsertOne implements Action {
    readonly type = OrganizationJobRoleActionTypes.UPSERT_JOB_ROLE;

    constructor(public job_role: OrganizationJobRoleModel) {}
}

export class UpsertMany implements Action {
    readonly type = OrganizationJobRoleActionTypes.UPSERT_JOB_ROLES;
    constructor(public job_roles: Array<OrganizationJobRoleModel>) {}
}

export class UpdateOne implements Action {
    readonly type = OrganizationJobRoleActionTypes.UPDATE_JOB_ROLE;
    constructor(public job_role: Update<OrganizationJobRoleModel>) {}
}

export class UpdateMany implements Action {
    readonly type = OrganizationJobRoleActionTypes.UPDATE_JOB_ROLES;
    constructor(public job_roles: Array<Update<OrganizationJobRoleModel>>) {}
}

export class DeleteOne implements Action {
    readonly type = OrganizationJobRoleActionTypes.DELETE_JOB_ROLE;
    constructor(public id: string) {}
}

export class DeleteMany implements Action {
    readonly type = OrganizationJobRoleActionTypes.DELETE_JOB_ROLES;
    constructor(public ids: Array<string>) {}
}

export class DeleteAll implements Action {
    readonly type = OrganizationJobRoleActionTypes.DELETE_ALL_JOB_ROLES;
    constructor(public ids: Array<string>) {}
}

export class DeleteState implements Action {
    readonly type = OrganizationJobRoleActionTypes.DELETE_ALL;
    constructor() {}
}

export type OrganizationJobRoleActions =
    | AddOne
    | AddMany
    | UpsertOne
    | UpsertMany
    | DeleteOne
    | DeleteMany
    | DeleteAll
    | DeleteState
    | UpdateOne
    | UpdateMany
    | UpdateSelectedJobRole;
